import React, { Component } from 'react'
import { Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as actions from "../../app/modules/Hamropalika/_redux/WardRedux";

class IndustrialBanking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }
    componentDidMount() {
        this.props.dispatch(actions.fetchInstitutionCount())
        const data = [
            {
                color: '#FFA800',
                bgColor: '#FFF4DE',
                icon: 'ic-borrowed-books',
                title: 'शैक्षिक सँस्था :',
                value: '0'
            },
            {
                color: '#3699FF',
                bgColor: '#E1F0FF',
                icon: 'hospital',
                title: 'स्वास्थ केन्द्र  :',
                value: '0'
            },
            {
                color: '#8950FC',
                bgColor: '#EEE5FF',
                icon: 'infrastructure',
                title: 'भौतिक पूर्वाधार :',
                value: '0'
            },
            {
                color: '#1BC5BD',
                bgColor: '#D7F9EF',
                icon: 'bank-1',
                title: 'बैंक तथा वित्तीय संस्था :',
                value: '0'
            },
            {
                color: '#2BA579',
                bgColor: '#DFF1EB',
                icon: 'park-1',
                title: 'सार्वजनिक स्थल :',
                value: '0'
            },
            {
                color: '#E05417',
                bgColor: '#FEE7DD',
                icon: 'government-1',
                title: 'सरकारी कार्यालयहरु :',
                value: '0'
            },
            {
                color: '#E05417',
                bgColor: '#FFE2E5',
                icon: 'building-1',
                title: 'संघ सँस्थाहरु :',
                value: '0'
            },
            // {
            //     color: '#58244B',
            //     bgColor: '#F4E1F0',
            //     icon: 'factory-1',
            //     title: 'उधोग तथा कलकारखाना :',
            //     value: '0'
            // },
            {
                color: '#E05417',
                bgColor: '#FEE7DD',
                icon: 'police-station-1',
                title: 'सुरक्षा निकाय सम्बन्धी :',
                value: '0'
            },
            {
                color: '#4AB58E',
                bgColor: '#DFF1EB',
                icon: 'natural-resources-1',
                title: 'प्राकृतिक श्रोत  :',
                value: '0'
            },
            {
                color: '#EE2D41',
                bgColor: '#FFE2E5',
                icon: 'temple-1',
                title: 'साँस्कृतिक सम्पदाहरु   :',
                value: '0'
            },
            {
                color: '#3F3CE1',
                bgColor: '#F0EFFF',
                icon: 'city-hall-building-architecture-1',
                title: 'गाउँ/नगर केन्द्र र वडा कार्यालय :',
                value: '0'
            }
        ]
        this.setState({ data })
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.detail.selectedInstitutionCount !== nextProps.detail.selectedInstitutionCount || this.props.detail.selectedLanguage !== nextProps.detail.selectedLanguage) {
            if (this.state.data.length) {
                const data = this.state.data.map((datum, index) => {
                    if (nextProps.detail.selectedInstitutionCount?.data[index]) {
                        const newData = {
                            ...datum,
                            title: nextProps.detail.selectedInstitutionCount?.data[index][nextProps.detail.selectedLanguage],
                            value: nextProps.detail.selectedInstitutionCount?.data[index]?.value,
                        }
                        return newData
                    }
                    return { ...datum }
                })
                this.setState({ data });
            }

        }
    }
    render() {
        return (
            <div className="row">
                <div className="col-12">
                    <Card>
                        <Card.Body>
                            <h4 className="mb-5">
                                {this.props.detail.institutionCountList.title[this.props.detail.selectedLanguage]}
                            </h4>
                            <div className="row row-cols-1 row-cols-xl-5 row-cols-md-3 row-cols-sm-2">
                                {
                                    this.state.data.map((datum, index) => {
                                        return datum.title && (<div className="d-flex col mb-5" key={index}>
                                            <span className="icon-container" style={{ backgroundColor: datum.bgColor }}>
                                                <i className={`icon-${datum.icon}`} style={{ color: datum.color }}></i>
                                            </span>
                                            <span className="values">
                                                <h6>{datum.title}</h6>
                                                <h6>{datum.value}</h6>
                                            </span>
                                        </div>
                                        )
                                    })
                                }
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    detail: state.ward,
})
export default connect(mapStateToProps)(IndustrialBanking);