import React, { Component } from "react";
import PersonCard from "../components/PersonCard";

class Staff extends Component {
	constructor(props) {
		super(props);
		this.state = {
			people: [],
		};
	}
	componentDidMount() {
		const people = [
			{
				name: "नरेन्द्र बहादुर खति",
				nameEnglish: "Narendra Nahadur Khati",
				designation: "प्रमुख प्रशासकीय अधिकृत",
				designationEnglish: "Chief Administrative Officer",
				email: "eo@dhangadhimun.gov.np",
				phoneEnglish: "9858433111",
				phone: "९८५८४३३१११",
				addressEnglish: "Dhangadhi Sub-Metropolitan City",
				address: "धनगढी उप-महानगरपालिका",
				image: "media/hamropalika/people/st4-naren.jpg",
				containerClass: "col-sm-6 col-md-12 col-xl-6",
			},
			{
				name: "सुरेन्द्र सिंह कार्की",
				nameEnglish: "Surendra Singh Karki",
				designation: "सूचना अधिकारी/प्रशासकीय अधिकृत",
				designationEnglish: "Information Officer",
				email: "dhangadhimun2013@gmail.com",
				phone: "९८४८७२०८४९",
				phoneEnglish: "9848720849",
				addressEnglish: "Dhangadhi Sub-Metropolitan City",
				address: "धनगढी उप-महानगरपालिका",
				image: "media/hamropalika/people/st6.jpg",
				containerClass: "col-sm-6 col-md-12 col-xl-6",
			},
		];
		this.setState({ people });
	}
	render() {
		return (
			<div className="row h-100">
				{this.state.people.map((person, index) => {
					return <PersonCard data={person} key={index} />;
				})}
			</div>
		);
	}
}

export default Staff;
