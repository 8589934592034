import React, { Component } from "react";
import { Card } from "react-bootstrap";

class ProgressBarCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			category: props.category,
		};
	}
	componentWillReceiveProps(nextProps) {
		if (nextProps.category !== this.props.category) {
			let category = nextProps.category;
			this.setState({ category });
		}
	}
	render() {
		return (
			<div className="col-lg-6">
				<Card
					className={`card-custom bg-light-${this.state.category.color} card-stretch gutter-b`}
				>
					<Card.Body>
						<a
							href="#"
							className={`card-title font-weight-bolder text-${this.state.category.color} text-hover-state-dark font-size-h6 mb-4 d-block`}
						>
							{this.state.category.title}
						</a>
						<div className="font-weight-bold font-size-sm">
							<span className="text-dark-75 font-size-h6 font-weight-bolder mr-2">
								{this.state.category.value}%
							</span>
							{this.state.category.smallText}
						</div>
						<div
							className={`progress progress-xs mt-7 bg-${this.state.category.color}-o-60`}
						>
							<div
								className={`progress-bar bg-${this.state.category.color}`}
								role="progressbar"
								style={{ width: `${this.state.category.value}%` }}
								aria-valuenow="50"
								aria-valuemin="0"
								aria-valuemax="100"
							></div>
						</div>
					</Card.Body>
				</Card>
			</div>
		);
	}
}

export default ProgressBarCard;
