import React, { Component } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import { Checkbox } from "@material-ui/core";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import L from "leaflet";
import { MapContainer, TileLayer, LayersControl, GeoJSON } from "react-leaflet";
import hash from "object-hash";
import Axios from "../../redux/axiosHandlerGeo";
import GeoJsonLayer from "./GeoJsonLayer";
import kanchanpur from "../../geojson/kanchanpur.json";
import { infastructure } from "../../variable/global";
// import kanchanpur from "../../geojson/bedkot.json";
import "leaflet/dist/leaflet.css";
import { connect } from "react-redux";

class EmergencyContactModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			sections: [],
		};
	}
	componentDidMount() {
		let sections = [
			{
				title: "अस्पताल",
				englishTitle: "Hospital",
				numbers: [
					{
						title: "धनगढी प्रहरी",
						englishTitle: "Dhangadhi Police",
						value: "०९१-५२१२९९",
						englishValue: "091-521299",
					},
					{
						title: "मेलमिलाप समिति: रत्ना कुमारी बोगटी बिष्ट",
						englishTitle: "Reconciliation Committee: Ratna Kumari Bogati Bista",
						value: "९८४८६९९८३३",
						englishValue: "९८४८६९९८३३",
					},
					{
						title: "दमकल सेवा",
						englishTitle: "Fire department",
						value: "१०१/०९१५२२१०७/९८०४६११५८८",
						englishValue: "101/ 091522107/ 9804611588",
					},
					{
						title: "एम्बुलेन्स सेवा",
						englishTitle: "Ambulance Service",
						value: "१०२/०९१-५२१४२९/९८६२४८४२०६/०९१-५२२१०७ ",
						englishValue: "102/ 091-521429/ 9862484206/ 091-522107",
					},
					{
						title: "बिधुत प्राधिकरण नो लाइट",
						englishTitle: "Nepal Electricity Authority, NoLight",
						value: "०९१४१७१४५",
						englishValue: "091417145",
					},
					{
						title: "जिल्ला प्रहरी कार्यालय कैलाली ",
						englishTitle: "District Police Office, Kailali",
						value: "१००/ ०९१५२११५०",
						englishValue: "100/ 091521150",
					},
					{
						title: "नेपाल रेडक्रस सोसाइटी एम्बुलेन्स",
						englishTitle: "Nepal Redcross Society Ambulance",
						value: "०९१५२१६००",
						englishValue: "091521600",
					},
					{
						title: "बाढी पूर्व सूचना कोष , स्रोत व्यक्त्ति लोकराज जोशी ",
						englishTitle:
							"Pre-Flood Information Fund, Contact Person Lokraj Joshi",
						value: "९८५८४२१८९२",
						englishValue: "9858421892",
					},
					{
						title: "सेती अञचल अस्पताल -मेन रोड",
						englishTitle: "Seti Zonal Hospital, Main Road",
						englishValue: "091-521271",
						value: "०९१-५२१२७१",
					},
					{
						title: "माया मेट्रो अस्पताल - चटकपुर-३",
						englishTitle: "Maya Metro Hospital, Chatakpur-3",
						value: "९८५८४२०४१४",
						englishValue: "9858420414",
					},
					{
						title: "नव जीवन अस्पताल -धनगढी",
						englishTitle: "Nawa Jiwan Hospital, Dhangadhi",
						value: "९१५२१२३३",
						englishValue: "91521233",
					},
					{
						title: "निसर्ग अस्पताल तथा रेसर्च केन्द्र - हसनपुर",
						englishTitle: "Nisarga Hospital and Research Center, Hasanpur",
						value: "९१५२६६८१",
						englishValue: "91526681",
					},
					{
						title: "कैलाली अस्पताल - मेन रोड",
						englishTitle: "Kailali Hospital, Main Road",
						englishValue: "9812695050",
						value: "९८१२६९५०५०",
					},
					{
						title: "ओम आसा अस्पताल - क्याम्पस रोड",
						englishTitle: "Om Aasha Hospital, Campus Road",
						englishValue: "91526649",
						value: "९१५२६६४९",
					},
					// {
					// 	title: "सेती अञचल अस्पताल - क्याम्पस रोड",
					// 	value: "091-521271",
					// },
					// {
					// 	title: "टिकापुर अस्पताल - टिकापुर",
					// 	value: "091-560150",
					// },
					// {
					// 	title: "मालाखेती अस्पताल - मालाखेती",
					// 	value: "91550887",
					// },
					// {
					// 	title: "वेल केर फारमेशी - भीमदत्त",
					// 	value: "985-8753066",
					// },
					{
						title: "सी पी अस्पताल निजी लिमिटेड - चटकपुर",
						englishTitle: "SiPi Hospital Private Limited, Chatakpur",
						englishValue: "091-410102",
						value: "०९१-४१०१०२",
					},
					{
						title: "धनगढी नेत्रालय आँखा अस्पताल - चटकपुर",
						englishTitle: "Dhangadhi Eye Hospital, Chatakpur",
						englishValue: "091-416072",
						value: "०९१-४१६०७२",
					},
				],
			},
		];
		this.setState({ sections });
	}
	renderContacts() {
		return (
			<>
				{this.state.sections.map((section, secIndex) => {
					return (
						<div key={secIndex}>
							{section.title ? (
								<>
									<h3 className="font-weight-bolder mb-4 mt-4">
										{this.props.selectedLanguage == "nepali"
											? section.title
											: section.englishTitle}
									</h3>
								</>
							) : null}
							<div className="row">
								{section.numbers.map((number, numIndex) => {
									return (
										<div
											className="col-lg-6 d-flex flex-wrap mb-4"
											key={numIndex}
										>
											<h5 className="font-weight-bold mr-2">
												{this.props.selectedLanguage == "nepali"
													? number.title
													: number.englishTitle}
												:{" "}
											</h5>
											<h5 className="text-primary font-weight-bold">
												{this.props.selectedLanguage == "nepali"
													? number.value
													: number.englishValue}
											</h5>
										</div>
									);
								})}
							</div>
						</div>
					);
				})}
			</>
		);
	}
	render() {
		return (
			<Modal
				{...this.props}
				size="xl"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				closeButton
			>
				<Modal.Body className="p-md-15">
					<div className="d-flex justify-content-between mb-6">
						<h1 className="font-weight-bolder">
							<i className="icon-emergency text-primary display-4"></i>{" "}
							{this.props.selectedLanguage == "nepali"
								? "आपतकालीन सम्पर्क"
								: "Emergency Contact"}
						</h1>
						<i className="fa fa-times" onClick={this.props.onHide}></i>
					</div>
					{this.renderContacts()}
				</Modal.Body>
			</Modal>
		);
	}
}
class MunicipalityDescription extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modalShow: false,
			boundData: [],
			wholeGeojson: [],
			location: {
				lat: 28.96725102178788,
				lng: 80.18087073179544,
			},
			zoom: 11,
			checkbox: {},
			wholeGeoJSON: {},
			showGeoJSON: [],
			sectionTitle: "",
		};
	}
	// 	this.getMunicpalId = this.getMunicpalId.bind(this);
	// }

	// isGeoJsonAlreadyFetched = (field) => {
	// 	const { wholeGeoJSON } = this.state;
	// 	return Boolean(wholeGeoJSON[field]);
	// };

	// fetchGeoJSON = (changedData) => {
	// 	const body = {
	// 		industries: "institute/industries",
	// 		cultural_heritage: "institute/cultural_heritage",
	// 		gov_office: "institute/gov_office",
	// 		forest: "institute/forest",
	// 		offices: "institute/offices",
	// 		infrastructure: "institute/infrastructure",
	// 		economic: "institute/economic",
	// 		education: "institute/education",
	// 		security: "institute/security",
	// 		palika_related_office: "institute/palika_related_office",
	// 		public_places: "institute/public_places",
	// 		health: "institute/health",
	// 	};

	// 	return new Promise((resolve, reject) => {
	// 		Axios.get(`/api/geojson/${body[changedData]}`)
	// 			.then((result) => {
	// 				const wholeGeoJSON = this.state.wholeGeoJSON;
	// 				this.setState(
	// 					{
	// 						wholeGeoJSON: {
	// 							...wholeGeoJSON,
	// 							[changedData]: result.data,
	// 						},
	// 					},
	// 					() => resolve()
	// 				);
	// 			})
	// 			.catch((err) => reject());
	// 	});
	// };

	// arrangeAllOption = async () => {
	// 	const { checkbox } = this.state;

	// 	const showGeoJSON = [];
	// 	let dataToCheck = [];

	// 	dataToCheck = dataToCheck.concat(infastructure);
	// 	const data = dataToCheck.map(async (each, i) => {
	// 		if (checkbox[each.value]) {
	// 			if (!this.isGeoJsonAlreadyFetched(each.value))
	// 				await this.fetchGeoJSON(each.value);

	// 			showGeoJSON.push(this.state.wholeGeoJSON[each.value]);
	// 		}
	// 	});
	// 	// wait until all promises resolve
	// 	await Promise.all(data);

	// 	this.setState({ showGeoJSON });
	// };

	// handleChange = (e) => {
	// 	e.persist();
	// 	this.setState(
	// 		({ checkbox }) => ({
	// 			checkbox: {
	// 				...checkbox,
	// 				[e.target.name]: e.target.checked,
	// 			},
	// 		}),
	// 		this.arrangeAllOption
	// 	);
	// };

	// style(feature) {
	// 	let skin = "blue";
	// 	if (feature.properties.FIRST_GaPa === "Bhimdatta") {
	// 		skin = "green";
	// 	}
	// 	return {
	// 		fillColor: skin,
	// 		weight: 1,
	// 		opacity: 1,
	// 		color: "black",
	// 		fillOpacity: 0.3,
	// 	};
	// }

	componentDidMount() {
		const boundData = [
			{
				key: "पुर्व",
				keyEnglish: "East",
				value: "कैलारी गाउँपालिका",
				valueEnglish: "Kailari Rural Municipality",
				class: "col-md-12",
			},
			{
				key: "उत्तर",
				keyEnglish: "North",
				value: "गोदावरी र गौरिगंगा नगरपालिका",
				valueEnglish: "Godawari and Gauriganga Municipality",
				class: "col-md-12",
			},
			{
				key: "पश्चिम",
				keyEnglish: "West",
				value: "मोहना नदी तथा कंचनपुर जिल्लाको सिमाना",
				valueEnglish: " Mohana River",
				class: "col-md-12",
			},
			{
				key: "दक्षिण",
				keyEnglish: "South",
				value: "मोहना नदी तथा अन्तर्राष्ट्रिय रुपमा भारतको सिमाना",
				valueEnglish: "India",
				class: "col-md-12",
			},
			{
				key: "क्षेत्रफल ",
				keyEnglish: "area",
				value: "२६१.७५ व कि.मि.",
				valueEnglish: "271.74 Sq. KM",
				class: "col-md-12",
			},
			{
				key: "वडा संख्या",
				keyEnglish: "ward",
				value: "१९",
				valueEnglish: "19",
				class: "col-md-12",
			},
			{
				key: "अक्षांश तथा देशान्तर",
				keyEnglish: "latitude and longitude",
				value: "२८.६८३३५९ उत्तर, ८०.६०८०६ पूर्व",
				valueEnglish: "28.6852° N, 80.6216° E",
				class: "col-12",
			},
			// {
			// 	key: "मुख्य सहरी क्षेत्र",
			// 	keyEnglish: "Main city area",
			// 	value: "",
			// 	valueEnglish: "",
			// 	class: "col-12",
			// },
		];
		this.setState({ ...this.state, boundData });
	}
	getMunicipalBounds() {
		let modalClose = () => this.setState({ modalShow: false });
		return (
			<div className="col-lg-5 pb-5 d-flex">
				<Card>
					<Card.Body>
						<div className="d-flex pb-8">
							<SVG
								className="primary-icon"
								src={toAbsoluteUrl("media/hamropalika/svg/map_marker.svg")}
								alt=""
							/>
							<h2 className="font-weight-black pl-3 pt-6">
								{this.props.selectedLanguage == "nepali"
									? "सिमाना"
									: "Boundaries"}
							</h2>
						</div>
						<div className="row">
							{this.state.boundData.map((data, index) => {
								return (
									<div className={`pb-3 ${data.class}`} key={index}>
										<div className="row">
											<div className="col-4">
												<h5 className="pr-2">
													{this.props.selectedLanguage == "nepali"
														? data.key
														: data.keyEnglish}
													:
												</h5>
											</div>
											<div className="col-8">
												<h5 className="text-primary">
													{this.props.selectedLanguage == "nepali"
														? data.value
														: data.valueEnglish}
												</h5>
											</div>
										</div>
									</div>
								);
							})}
						</div>
						<div className="float-right ">
							<Button
								className="px-10 emergency-button"
								variant="light-danger"
								size="lg"
								onClick={() => this.setState({ modalShow: true })}
							>
								{" "}
								<i className="icon-emergency"></i>{" "}
								{this.props.selectedLanguage == "nepali"
									? "आपतकालीन सम्पर्क"
									: "Emergency Contact"}
							</Button>

							<EmergencyContactModal
								selectedLanguage={this.props.selectedLanguage}
								show={this.state.modalShow}
								onHide={modalClose}
							/>
						</div>
					</Card.Body>
				</Card>
			</div>
		);
	}
	getMunicpalId() {
		return (
			<div className="col-lg-7 pb-5 d-flex ">
				<Card>
					<Card.Body>
						<div className="d-flex pb-8">
							<SVG
								className="primary-icon"
								src={toAbsoluteUrl("media/hamropalika/svg/municipality_id.svg")}
								alt=""
							/>
							<h2 className="font-weight-black pl-3 pt-6">
								{this.props.selectedLanguage == "nepali"
									? "उप-महानगरपालिका अबस्थित  नक्शा"
									: "Sub-Metropolitan City Location Map"}
							</h2>
						</div>

						<img
							className="w-100"
							src={toAbsoluteUrl("/media/hamropalika/static-map.png")}
							alt=""
						/>
					</Card.Body>
				</Card>
			</div>
		);
	}
	render() {
		return (
			<>
				<div className="row">
					{this.getMunicpalId()}
					{this.getMunicipalBounds()}
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	selectedLanguage: state.ward.selectedLanguage,
});
export default connect(mapStateToProps)(MunicipalityDescription);
